import React, {Component} from 'react'
import AvatarEditor from 'react-avatar-editor'
import Dropzone from 'react-dropzone'
import {updateExistingImageUpload} from '../FileStorageUtils'
import {
  getCurrentUser,
} from "../../Common/LocalStorage";
import { AiOutlineRotateRight, AiOutlinePlus, AiOutlineMinus, AiOutlineDelete, AiOutlineCopy } from "react-icons/ai";
import {RxLockOpen2,RxLockClosed} from "react-icons/rx";
import {BsBorderOuter,BsBorder,BsFillInfoSquareFill,BsPencilFill} from "react-icons/bs";
import './image_editor.css'
import { t } from 'i18next';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { MdAutoFixHigh } from "react-icons/md";
import { EditImageWithAIDialog } from './EditImageWithAIDialog';


export default class ImageEditor extends React.Component {
    state = {
      private_image: this.props.private_image,
      image: this.props.img_url,
      image_name: this.props.img_name,
      image_id: this.props.img_id,
      allowZoomOut: false,
      position: { x: 0.5, y: 0.5 },
      scale:this.props.scale || 1,
      rotate: this.props.rotate || 0,
      border: this.props.border ,
      borderRadius: 0,
      preview: null,
      width: this.props.width || 150,
      height: this.props.height || 150,
      disableCanvasRotation: false,
      isTransparent: false,
      backgroundColor: null,
      isShown: this.props.is_shown,
      onImageUploadActions: this.props.onImageUploadActions,
      onImageUploadDone: this.props.onImageUploadDone,
      onImgPrivateChange: this.props.onImgPrivateChange,
      onImgEditAIActions: this.props.onImgEditAIActions,
      handlePencilClick:this.props.handlePencilClick,
      isEditMode: false,
      readOnly: this.props.read_only,
      isPlaceholder:false,
      lastTapTime:0,
      showEditWithAI:false,
      img_prompt: this.props.img_prompt,
      img_system_prompt: this.props.img_system_prompt,
      img_user_prompt: this.props.img_user_prompt,
      isPermittedUser : this.props.isPermittedUser
    }

    constructor(props) {
      super(props);
      this.wrapperRef = React.createRef();
      this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
      document.addEventListener("mousedown", this.handleClickOutside);
      document.addEventListener("touchstart", this.handleClickOutside);

      if(this.props.isPrivate){
        this.props.getImgUrl(this.props.img_url,this.props.isPrivate,this.urlChanged,this)
      }
    }

    componentDidUpdate(prevProps, prevState) {
      if (this.props.isPrivate && this.props.private_image && this.props.private_image[prevProps.img_url])
      {
        if (this.props.private_image[this.props.img_url] != 
            prevProps.private_image[prevProps.img_url]) // Check if img_url changed
         {
          this.props.getImgUrl(this.props.img_url, this.props.isPrivate, this.urlChanged, this, false, this.props.private_image[this.props.img_url])
        }
      }
    }
    
  
    componentWillUnmount() {
      document.removeEventListener("mousedown", this.handleClickOutside);
      document.removeEventListener('touchstart', this.handleClickOutside);
    }

  
    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
      if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
        if (this.state.isEditMode)
        {
          console.log("storing image changes")
          this.handleSave()
        } 
        this.setState({isEditMode:false})
      }
    }

    urlChanged(url, obj, is_placeholder=false) {
      let state = is_placeholder ? {image: url, isPlaceholder:true} : {image: url}
      obj.setState(state)
    }
    
    handleSave = () => { 
      this.props.onImageUploadActions(this.props.img_name, this.props.img_id, this.state.width, this.state.height, this.state.rotate, this.state.scale,this.state.border)

      const img = this.editor.getImageScaledToCanvas().toDataURL()
      const rect = this.editor.getCroppingRect()
      let uploadedURL = updateExistingImageUpload(this.editor.getImageScaledToCanvas().toDataURL("image/jpeg"), 
                                this.props.img_name, 
                                this.props.onImageUploadDone,
                                getCurrentUser().uid)

      console.log("uploadedURL img file="+uploadedURL)

      this.setState({
        preview: {
          img,
          rect,
          scale: this.state.scale,
          width: this.state.width,
          height: this.state.height,
          border: this.state.border,
          borderRadius: this.state.borderRadius,
        },
        //isEditMode:!this.state.isEditMode
      })
    }

    switchEditMode = (e) => {
      //e.stopPropagation();
      if(!this.props.read_only){
      console.log("current edit mode:"+this.state.isEditMode)
      if (this.state.isEditMode)
      {
        console.log("saving image data")
        this.handleSave()
      }
      this.setState({
        isEditMode:!this.state.isEditMode
      })
      //this.state.isEditMode = !this.state.isEditMode
      console.log("edit mode post change:"+this.state.isEditMode)
      }
    }

    handleDelete = () =>{
      this.props.is_shown(this.props.img_id)
    }

      writeToCanvas=()=>{
      return new Promise((res)=>{
          const canvas = document.createElement('canvas');
          const ctx=canvas.getContext('2d');
          var img=new Image();
          img.src=this.props.img_url;
          img.crossOrigin="anonymous"
          img.onload = ()=>{
            canvas.width = img.naturalWidth;
            canvas.height= img.naturalHeight;
          ctx.drawImage(img, 0,0);
            canvas.toBlob((blob)=>{
              res(blob);
            },'image/png')
          }
      })
    }

    editImageWithAI = () => {
      this.setState({ imageOpacity: 0.2 });
      this.setState({ showEditWithAI: true })
    };

    onEditImageWithAIClose = () => {
      this.setState({ imageOpacity: 1 });
      this.setState({ showEditWithAI: false })
    };

    handleImageChange = (newImageUrl) => {
      this.setState({ image: '' }, () => {  // Clear image first
        this.setState({ image: newImageUrl });  // Set new image
      });
    };

    /*ImgEditAIActions = async (new_prompt, setIsUploading) => {
      await this.props.onImgEditAIActions(this.state.image, new_prompt, this.state.img_user_prompt,
              this.state.img_system_prompt, this.state.width + "x" + this.state.height, setIsUploading).then(response => {
        if (response && response.updated_url)
        {
          console.log("response.updated_url:"+response.updated_url)
          this.handleImageChange(response.updated_url)  
          return response.updated_url
        }
        else
          return false
      })
    }*/

    ImgEditAIActions = async (new_prompt, setIsUploading) => {
      const response = await this.props.onImgEditAIActions(
        this.state.image, new_prompt, this.state.img_user_prompt,
        this.state.img_system_prompt, this.state.width + "x" + this.state.height, setIsUploading
      );
    
      if (response && response.updated_url) {
        console.log("response.updated_url:", response.updated_url);
        this.handleImageChange(response.updated_url);
        return response.updated_url;
      } else {
        return false;
      }
    };
    

    setShowUpgradeModal = (value) => {
      return this.props.setShowUpgradeModal(value)
    }

    copyImage = async () => {
      const blob = await this.writeToCanvas();
      try {
        await navigator.clipboard.write([
        new ClipboardItem({
          [blob.type]: blob,
        })
      ]);
        console.log("image copied to clipboard");
      } catch (e) {
        console.log(e);
      } 
    }
    handleScale = (e) => {
      const scale = parseFloat(e.target.value)
      this.setState({ scale })
    }

    handlePrivateToNonPrivate = (e) => {
      this.props.onImgPrivateChange(this.props.img_id,true,this.props.img_url)
    }
    handleNonPrivateToPrivate = (e) => {
      this.props.onImgPrivateChange(this.props.img_id,false,'')
    }
    handleAllowZoomOut = ({ target: { checked: allowZoomOut } }) => {
      this.setState({ allowZoomOut })
    }
  
    handleDisableCanvasRotation = ({
      target: { checked: disableCanvasRotation },
    }) => {
      this.setState({ disableCanvasRotation })
    }
  
    rotateScale = (e) => {
      const scale = parseFloat(e.target.value)
      e.preventDefault()
      this.setState({
        rotate: scale,
      })
    }

    onMouseMove = (e) => {
      e.preventDefault()
      console.log("mouse move done")
    }
  
    rotateLeft = (e) => {
      e.preventDefault()
      this.setState({
        rotate: (this.state.rotate - 90) % 360 ,
      })
    }
  
    rotateRight = (e) => {
      e.preventDefault()
      this.setState({
        rotate: (this.state.rotate + 90) % 360 ,
      })
      console.log("rotating, new rotate value:"+this.state.rotate)
    }
  
    handleBorderRadius = (e) => {
      const borderRadius = parseInt(e.target.value)
      this.setState({ borderRadius })
    }
  
    handleXPosition = (e) => {
      const x = parseFloat(e.target.value)
      this.setState({ position: { ...this.state.position, x } })
    }
  
    handleYPosition = (e) => {
      const y = parseFloat(e.target.value)
      this.setState({ position: { ...this.state.position, y } })
    }
  
    handleWidth = (e) => {
      const width = parseInt(e.target.value)
      this.setState({ width })
    }

    increaseImageSize = () =>
    {
      this.setState({
        width: (this.state.width + 5),
        height: (this.state.height + 5)
      })
    }

    decreaseImageSize = () =>
    {
      this.setState({
        width: (this.state.width - 5),
        height: (this.state.height - 5)
      })
    }

    removeBorder = () =>
    {
      this.setState({
        border: 0
      })
    }

    applyBorder = () =>
    {
      this.setState({
        border: 4
      })
    }
  
    handleHeight = (e) => {
      const height = parseInt(e.target.value)
      this.setState({ height })
    }
  
    logCallback(e) {
      // eslint-disable-next-line no-console
      //console.log('callback', e)
    }
  
    setEditorRef = (editor) => {
      if (editor) this.editor = editor
    }
  
    handlePositionChange = (position) => {
      console.log("position changed")
      //uncomment the below to enable panning
      //this.setState({ position })
    }
  
    setBackgroundColor = (e) => {
      this.setState({ backgroundColor: e.target.value })
    }
  
    setTransparent = (e) => {
      const isTransparent = e.target.checked;
      // set color to white initially
      const backgroundColor = isTransparent ? '#FFFFFF' : null
      this.setState({ backgroundColor, isTransparent })
    }
  
    handleTapStart = () => {
    const currentTime = new Date().getTime();
    const tapThreshold = 300; // Adjust this value to control the double-tap speed
    if (currentTime - this.state.lastTapTime < tapThreshold) {
      this.switchEditMode(); // Double-tap detected, trigger the action
    }
    this.setState({ lastTapTime: currentTime });
    };

    privateImgPencilClick = (img_url) => {
      console.log('changed pencilClicked to true')
      this.setState({pencilClicked:true})
      console.log('changed pencilClicked to true:'+this.state.pencilClicked)
      this.props.handlePencilClick(img_url)
    }

    render() {
      return (
        <div className={this.state.isEditMode ? "image_edit_mode" : ""}
        ref={this.wrapperRef}>
          {this.state.isEditMode ? 
            (<div className="buttonsRow image-buttonsRow">
          {this.state.img_prompt &&
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t("img_ai_edit")}</Tooltip>}>
            <span className="d-inline-block">
              <button type="button" className="hoverable float btn-circle" onClick={this.editImageWithAI} onTouchStart={this.editImageWithAI} ><MdAutoFixHigh/></button>
            </span>
            </OverlayTrigger>}
          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t("img_delete_btn")}</Tooltip>}>
          <span className="d-inline-block">
            <button type="button" className="hoverable float btn-circle" onClick={this.handleDelete} onTouchStart={this.handleDelete} ><AiOutlineDelete/></button>
          </span>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t("img_copy_btn")}</Tooltip>}>
          <span className="d-inline-block">
            {!this.props.isPrivate &&<button type="button" className="hoverable float btn-circle" onClick={this.copyImage} onTouchStart={this.copyImage}><AiOutlineCopy/></button>}
          </span>
          </OverlayTrigger>
          {this.props.isPrivate?
          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t("img_public_btn")}</Tooltip>}>
          <span className="d-inline-block">
            <button type="button" className="hoverable float btn-circle" onClick={this.handlePrivateToNonPrivate} onTouchStart={this.handlePrivateToNonPrivate}><RxLockClosed/></button>
          </span>
          </OverlayTrigger>: 
          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t("img_private_btn")}</Tooltip>}>
          <span className="d-inline-block">
            <button type="button" className="hoverable float btn-circle" onClick={this.handleNonPrivateToPrivate} onTouchStart={this.handleNonPrivateToPrivate}><RxLockOpen2/></button>
          </span>
          </OverlayTrigger>}
          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t("img_rotate_btn")}</Tooltip>}>
          <span className="d-inline-block">
            <button type="button" className="hoverable float btn-circle" onClick={this.rotateRight} onTouchStart={this.rotateRight}><AiOutlineRotateRight/></button>
          </span>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t("img_increase_btn")}</Tooltip>}>
          <span className="d-inline-block">
            <button type="button" className="hoverable float btn-circle" onClick={this.increaseImageSize} onTouchStart={this.increaseImageSize}><AiOutlinePlus/></button>
          </span>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t("img_decrease_btn")}</Tooltip>}>
          <span className="d-inline-block">
            <button type="button" className="hoverable float btn-circle" onClick={this.decreaseImageSize} onTouchStart={this.decreaseImageSize}><AiOutlineMinus/></button>
          </span>
          </OverlayTrigger>
          {this.state.border==4?
          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t("img_remove_border_btn")}</Tooltip>}>
          <span className="d-inline-block">
            <button type="button" className="hoverable float btn-circle" onClick={this.removeBorder} onTouchStart={this.removeBorder}><BsBorder/></button>
          </span>
          </OverlayTrigger>:
          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t("img_add_border_btn")}</Tooltip>}>
          <span className="d-inline-block">
            <button type="button" className="hoverable float btn-circle" onClick={this.applyBorder} onTouchStart={this.applyBorder}><BsBorderOuter/></button>
          </span>
          </OverlayTrigger>
          }

            <span className="hoverable">
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t("img_zoom_btn")}</Tooltip>}>
          <span className="d-inline-block">
              <button className="float btn-range">
                <input
                className="range"
                name="scale"
                type="range"
                onChange={this.handleScale}
                onTouchStart={this.handleScale}
                min={this.state.allowZoomOut ? '0.1' : '1'}
                max="2"
                step="0.05"
                defaultValue="1"
                />
              </button>
              </span>
          </OverlayTrigger>
            </span>            
          </div>): <div></div>}
          {this.state.isPlaceholder ? 
                <OverlayTrigger className="private_overlay" 
                                placement="right"
                                overlay={<Tooltip id="tooltip-disabled">{t("img_private_content_prefix")+this.props.img_url}</Tooltip>}>
                  {this.state.readOnly ?
                  <span><BsFillInfoSquareFill className="private_info"/> </span> :
                  <span><BsPencilFill className="image_edit_hover private_info_pencil" onClick={()=>this.privateImgPencilClick(this.props.img_url)}  onTouchStart={()=>this.privateImgPencilClick(this.props.img_url)}/> </span>
                  }
                </OverlayTrigger>
              :
                !this.state.readOnly && !this.state.isEditMode && 
                <div>
                  <span><BsPencilFill className="image_edit_hover private_info_pencil" onClick={this.switchEditMode} onTouchEnd={this.switchEditMode}/> </span>
                  {this.state.img_prompt && <span><MdAutoFixHigh className="image_edit_hover image_edit_ai" onClick={this.editImageWithAI} onTouchEnd={this.editImageWithAI}/> </span>}
                </div>
                
          }
          <div onDoubleClick={this.switchEditMode} onTouchStart={this.handleTapStart}>
                <AvatarEditor
                  ref={this.setEditorRef}
                  scale={parseFloat(this.state.scale)}
                  border={this.state.border}
                  color={[0,0,0]}
                  onMouseMove={this.onMouseMove}
                  disableBoundaryChecks={false}
                  width={this.state.width}
                  height={this.state.height}
                  position={this.state.position}
                  onPositionChange={(e) => this.handlePositionChange(e)}
                  rotate={this.state.rotate}
                  borderRadius = {this.state.borderRadius}
                  backgroundColor={this.state.backgroundColor}
                  onLoadFailure={this.logCallback.bind(this, 'onLoadFailed')}
                  onLoadSuccess={this.logCallback.bind(this, 'onLoadSuccess')}
                  onImageReady={this.logCallback.bind(this, 'onImageReady')}
                  image={this.state.image}
                  className= {this.state.isEditMode ? "editor-canvas" : (this.state.isPlaceholder ? "editor-canvas placholder" : "image_read_mode")}
                  disableCanvasRotation={this.state.disableCanvasRotation}
                  crossOrigin = "anonymous"
                  style={{ opacity: this.state.imageOpacity }}
                />
          </div>
          <div>
            <EditImageWithAIDialog
                    onHide = {this.onEditImageWithAIClose}
                    show = {this.state.showEditWithAI}
                    imgDesc = {this.state.img_user_prompt ? this.state.img_user_prompt : this.state.img_prompt}
                    onImgEditAIActions = {this.ImgEditAIActions}
                    onImgEditAIDone = {this.handleImageChange}
                    setShowUpgradeModal = {this.setShowUpgradeModal}   
                    isPermittedUser = {this.state.isPermittedUser}             
                  />
          </div>
        </div>
      )
    }
  }