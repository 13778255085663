import React, { useState, useEffect} from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CloseModalButton from "../../Common/CloseModalButton";

export const EditImageWithAIDialog = ({ onHide, show, 
                                        imgDesc, onImgEditAIActions, 
                                        onImgEditAIDone, setShowUpgradeModal, isPermittedUser }) => {
  const {t,i18n} = useTranslation();
  const [error, setError] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const direction = (i18n.language === "he" ? "rtl" : "ltr")
  const [userImgPrompt, setImgUserPrompt] = useState(imgDesc)
  

  const handleFormClose = () => {
    setIsUploading(false)
    setError(null)
    onHide();
  };

  const handleInputChange = (event) => {
    setImgUserPrompt(event.target.value);
  };

  /*const onSave = async () => {
    let result = false
    await onImgEditAIActions(userImgPrompt, setIsUploading).then((response) => {
      console.log("image_edit_result:"+response)
      result = response
      return result
    })
    return result
  }*/

  const onSave = async () => {
    const result = await onImgEditAIActions(userImgPrompt, setIsUploading);
    console.log("image_edit_result:", result);
    return result;
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isPermittedUser){
      setShowUpgradeModal(true)
      handleFormClose()
    }
    else{
      setIsUploading(true)
      await onSave().then(async (response) => {
        if (response) handleFormClose()
        else{
          setError(t("img_ai_edit_dialog_error"))
        }
      })
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Modal show={show} onHide={handleFormClose} size="lg" centered dir={direction}>
        <Modal.Header>
          <Modal.Title>{t("img_ai_edit_dialog_header")}</Modal.Title>
          <CloseModalButton onClick={handleFormClose} />
        </Modal.Header>
        <Modal.Body>
        {!isUploading ? 
          <Form.Group>
            <Form.Control
              as="textarea"
              type="text"
              id="descriptionInput"
              value={userImgPrompt}
              onChange={handleInputChange}
              placeholder={t("img_ai_edit_inbox_placeholder")}
              style={{
                  height: '150px',
                  textAlign: 'left', 
              }}
            />
          </Form.Group>
            :
            <div className="load_spinner" style={{ display: "flex", justifyContent: "center", alignItems: "center", margin:"5px" }}>
              <small>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </small>
            </div>
          }
          {error && <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSubmit} disabled={isUploading}>
            {t("img_ai_edit_dialog_update")}
          </Button>
        </Modal.Footer>
      </Modal>
    </Form>
  );
};
